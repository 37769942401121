<template>
  <div class="loginSetting">
    <div class="l_title">安全登录</div>
    <el-form
      :model="formInline"
      class="login_form"
      label-width="130px"
      :disabled="$route.query.status == 'details'"
    >
      <el-form-item label="密码有效时长:">
        每隔
        <el-input
          class="ls_input"
          @blur="saveEvent('PASSWORD_VALIDITY_PERIOD')"
          v-model="formInline.PASSWORD_VALIDITY_PERIOD"
          maxlength="5"
          clearable>
        </el-input>
        天，用户需要对密码进行重置更新。
      </el-form-item>
      <el-form-item label="密码输入限制:">
        账号输错
        <el-input
          class="ls_input"
          @blur="saveEvent('PASSWORD_ENTRY_RESTRICTION')"
          v-model="formInline.PASSWORD_ENTRY_RESTRICTION"
          maxlength="5"
          clearable>
        </el-input>
        次以后，账号冻结
        <el-input
          class="ls_input"
          @blur="saveEvent('PASSWORD_FREEZING_DURATION')"
          v-model="formInline.PASSWORD_FREEZING_DURATION"
          maxlength="5"
          clearable>
        </el-input>
        分钟。
      </el-form-item>
      <el-form-item label="账号登录时长:">
        登录超过
        <el-input
          class="ls_input"
          @blur="saveEvent('NO_OPERATION_RETURN_PERIOD')"
          v-model="formInline.NO_OPERATION_RETURN_PERIOD"
          maxlength="5"
          clearable>
        </el-input>
        分钟无操作，系统提醒登录超时，并对账号进行下线处理。
      </el-form-item>
      <!-- <el-form-item label="PC短信登录方式:">
        <el-switch v-model="formInline.WEB_WHETHER_SMS_LOGIN_IS_SUPPORTED" active-value="1" inactive-value="0" @change="saveEvent('WHETHER_SMS_LOGIN_IS_SUPPORTED')"></el-switch>
        &nbsp;<span>开启后，已绑定手机的账号，用户可通过手机号码进行登录。</span>
      </el-form-item> -->
      <el-form-item label="短信登录方式:">
        <el-switch v-model="formInline.WHETHER_SMS_LOGIN_IS_SUPPORTED" active-value="1" inactive-value="0" @change="saveEvent('WHETHER_SMS_LOGIN_IS_SUPPORTED')"></el-switch>
        &nbsp;<span>开启后，已绑定手机的账号，用户可通过手机号码进行登录。</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'loginSetting',
  data() {
    return {
      formInline: {
        PASSWORD_VALIDITY_PERIOD: null,
        PASSWORD_ENTRY_RESTRICTION: null,
        PASSWORD_FREEZING_DURATION: null,
        NO_OPERATION_RETURN_PERIOD: null,
        WHETHER_SMS_LOGIN_IS_SUPPORTED: 1,
      }
    }
  },
  created() {
    this.getLoginSetting();
  },
  methods: {
    getLoginSetting() {
      this.$request.getMajorListLogin().then(res => {
        if(res.data.code == 0) {
          this.formInline = res.data.data;
        }
      })
    },
    saveEvent(key) {
      let dto = {
        key,
        value: this.formInline[key]
      }
      this.$request.loginSetting(dto).then(res => {
        if(res.data.code == 0) {
          this.Success('操作成功');
        }
      }).catch(err => {
        this.formInline[key] = this.formInline[key] == 1 ? '0' : 1
      })
    }
  },
}
</script>

<style lang='less'>
  .loginSetting {
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px;
    .l_title {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .login_form {
      .ls_input {
        width: 100px;
        & > input {
          width: inherit;
        }
      }
    }
  }
</style>